import React from 'react'
import { graphql } from 'gatsby'

import Dots from '../assets/images/dots.inline.svg'
import Button from '../components/Button'
import Markdown from '../components/Markdown'
import getPath from '../utils/getPath'

const ImageEtTexte = ({
  titre,
  texte,
  imageADroite,
  image,
  couleur,
  grilleDePoints,
  couleurPleine,
  lien,
  titreDuLien,
}) => {
  return (
    <div className={`relative ${couleurPleine ? 'mt-12' : ''}`}>
      <div
        className={`absolute top-0 bottom-0 z-index-1 bg-${couleur}-500 bg-opacity-7 ${
          imageADroite ? 'right-0' : 'left-0'
        }`}
        style={{ width: couleurPleine ? '100%' : 'calc(50vw - 500px)' }}
      ></div>
      <div className="container relative grid grid-cols-1 py-12 mx-auto md:grid-cols-2 z-index-2">
        <div
          className={`relative p-4 my-auto ${
            imageADroite && 'md:order-2'
          } text-${couleur}-500`}
        >
          {!!image && !!image.url && (
            <img src={image.url} alt={image.alt} className="relative z-20" />
          )}
          {grilleDePoints && (
            <Dots
              className={`absolute fill-current bottom-3 ${
                imageADroite ? 'right-3' : 'left-3'
              } z-dex-10`}
            />
          )}
        </div>
        <div className="p-4 my-auto">
          <h2 className="mb-6 text-3xl font-bold">{titre}</h2>
          <Markdown accentColor={couleur} tickClassName={`text-${couleur}-500`}>
            {texte}
          </Markdown>

          {!!lien && (
            <Button to={getPath(lien)} variant={couleur} className="mt-6">
              {titreDuLien}
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

export const imageEtTexteFragment = graphql`
  fragment DatoCmsBlocImageEtTexteFragment on DatoCmsBlocImageEtTexte {
    model {
      apiKey
    }
    id
    couleur
    titre
    texte
    grilleDePoints
    titreDuLien
    lien {
      ... on DatoCmsPage {
        ...DatoCmsPageLinkFragment
      }
    }
    imageADroite
    image {
      url
      alt
    }
    couleurPleine
  }
`

export default ImageEtTexte

import React from 'react'
import { graphql } from 'gatsby'

import StructuredText from '../components/StructuredText'

const Texte = ({ texteContenu }) => {
  return (
    <div className={`container relative p-10 mx-auto`}>
      {texteContenu.value && <StructuredText data={texteContenu} />}
    </div>
  )
}

export const texteFragment = graphql`
  fragment DatoCmsBlocTexteFragment on DatoCmsBlocTexte {
    model {
      apiKey
    }
    id
    texteContenu: contenu {
      value
    }
  }
`

export default Texte

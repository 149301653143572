import React from 'react'
import { graphql, Link } from 'gatsby'
import MoyenButtons from '../../components/MoyenButtons'
import Formules from '../../components/Formules'

const HeroFormule = ({ titre, sousTitre, moyenAssure, prefix }) => {
  return (
    <div className="container py-12 mx-auto">
      <h1 className="mb-12 text-5xl font-bold text-center">{titre}</h1>
      <div
        className="p-4 mb-12 text-center"
        dangerouslySetInnerHTML={{ __html: sousTitre }}
      />
      <MoyenButtons prefix={prefix} />
      {prefix === 'formules' && !!moyenAssure.notice && (
        <div
          className="max-w-3xl p-6 mx-auto mb-12 text-center bg-gray-100 rounded"
          dangerouslySetInnerHTML={{ __html: moyenAssure.notice }}
        />
      )}
      {prefix === 'formules' && <Formules moyenAssureSlug={moyenAssure.slug} />}
    </div>
  )
}

export const heroFormuleFragment = graphql`
  fragment DatoCmsBlocHeroFormuleFragment on DatoCmsBlocHeroFormule {
    model {
      apiKey
    }
    id
    titre
    sousTitre
    moyenAssure {
      slug
      couleur
      notice
    }
  }
`

export default HeroFormule

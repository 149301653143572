import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import FAQQuestion from './FAQQuestion'
import Search from '../../assets/images/search.inline.svg'
import FollowingSidebar from '../../components/FollowingSidebar'
import slugify from 'slugify'

const isIncluded = (search, text) => {
  return (
    !!search && !!text && text.toLowerCase().indexOf(search.toLowerCase()) > -1
  )
}

const isAvailable = (article, sujet, moyenAssure, search) => {
  return (
    article.sujetFaq.id === sujet.id &&
    article.moyenAssure.slug === moyenAssure.slug &&
    (search === '' ||
      isIncluded(search, article.titre) ||
      isIncluded(search, article.chapo))
  )
}

const FAQ = ({ moyenAssure }) => {
  const [search, setSearch] = useState('')
  const data = useStaticQuery(graphql`
    query FAQ {
      allDatoCmsArticle(filter: { categorie: { slug: { eq: "faq" } } }) {
        nodes {
          id
          slug
          titre
          chapo
          sujetFaq {
            id
          }
          moyenAssure {
            slug
          }
          categorie {
            slug
          }
        }
      }
      allDatoCmsSujetFaq(sort: { order: ASC, fields: position }) {
        nodes {
          id
          titre
        }
      }
    }
  `)

  const sujetsFaq = data.allDatoCmsSujetFaq.nodes

  const filteredSujetsFaq = sujetsFaq
    .map((sujet) => {
      const sujetsQuestions = data.allDatoCmsArticle.nodes.filter((article) =>
        isAvailable(article, sujet, moyenAssure, search)
      )
      // console.log(sujetsQuestions)
      return {
        ...sujet,
        sujetsQuestions,
      }
    })
    .filter((sujet) => sujet.sujetsQuestions.length > 0)

  const titres = filteredSujetsFaq.map(({ titre }) => {
    return {
      titre,
      id: slugify(titre, {
        strict: true,
        lower: true,
        locale: 'fr',
      }),
    }
  })

  return (
    <FollowingSidebar titres={titres} forceRecalculationKey={search}>
      <div className="relative w-full">
        <Search className="absolute text-gray-300 top-3 left-3" />
        <input
          type="text"
          placeholder="Rechercher des réponses…"
          className="w-full py-3 pl-12 pr-3 mb-6 leading-tight text-gray-700 rounded appearance-none"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div id="sections">
        {filteredSujetsFaq.map((sujet) => {
          const questions = sujet.sujetsQuestions
          if (questions.length === 0) {
            return ''
          }
          return (
            <div className="relative my-6 faq-section" key={sujet.id}>
              <div
                id={slugify(sujet.titre, {
                  strict: true,
                  lower: true,
                  locale: 'fr',
                })}
                className="absolute left-0 -top-28"
              />
              <h2 className="mb-4 text-3xl font-bold">{sujet.titre}</h2>
              {questions.map((question) => (
                <FAQQuestion
                  {...question}
                  key={question.id}
                  couleur={moyenAssure.couleur}
                />
              ))}
            </div>
          )
        })}
      </div>
    </FollowingSidebar>
  )
}

export default FAQ

export const faqFragment = graphql`
  fragment DatoCmsBlocFaqFragment on DatoCmsBlocFaq {
    model {
      apiKey
    }
    id
    titre
  }
`

import React, { useState } from 'react'
import { useRecoilState } from 'recoil'
import { graphql } from 'gatsby'

import Button from '../components/Button'
import VehiculesSelect from '../components/VehiculesSelect'
import Markdown from '../components/Markdown'

import Tick from '../assets/images/tick.inline.svg'
import resultsState from '../components/souscription/resultsState'
import ecranState from '../components/souscription/ecranState'
import openExternalVaeLink from "../utils/openExternalVaeLink";
import openExternalJolteeLink from "../utils/openExternalJolteeLink";

const Hero = ({ image, texte, items, titre, vehicule }) => {
  const [vehicle, setVehicle] = useState('')

  const [resultats, setResultats] = useRecoilState(resultsState)
  const [_, setIndexEcran] = useRecoilState(ecranState)

  return (
    <div className="container grid grid-cols-1 py-12 mx-auto md:grid-cols-2">
      <div className="p-4 my-auto">
        <h1 className="mb-6 text-5xl font-bold lg:text-6xl">
          {titre
            .replace(' *', ' *$')
            .split('*')
            .map((line, index) => (
              <span
                className={`block ${
                  line.indexOf('$') === 0 && ' text-green-500'
                }`}
                key={index}
              >
                {line.indexOf('$') === 0 ? line.substring(1) : line}
              </span>
            ))}
        </h1>
        <Markdown>{texte}</Markdown>
        <div className="block my-8 md:flex">
          {!vehicule && (
            <div className="relative flex-grow h-12 mb-4 mr-4 text-gray-300 border border-gray-300 rounded md:mb-0">
              <VehiculesSelect
                value={vehicle}
                onChange={(e) => setVehicle(e.target.value)}
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="absolute w-6 h-6 -mt-3 text-gray-300 pointer-events-none right-2 top-1/2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </div>
          )}
          <Button
            variant="green"
            onClick={() => {
              if (!!vehicle || !!vehicule) {
                  if (vehicule?.clefApi === 'velo_electrique' || vehicle === 'velo_electrique') {
                      openExternalVaeLink();
                  } else {
                      openExternalJolteeLink(vehicle)
                  }
              }
            }}
            disabled={!vehicule && !vehicle}
          >
            J'obtiens mon tarif
          </Button>
        </div>
        {!!items && items.length > 0 && (
          <ul className="flex flex-wrap items-center">
            {items.map((item) => (
              <li
                key={item.id}
                className="flex items-center mr-4 text-gray-300"
              >
                <Tick className="mr-1 stroke-current" />
                <span>{item.titre}</span>
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="p-4 my-auto">
        {!!image && !!image.url && <img src={image.url} alt={image.alt} />}
      </div>
    </div>
  )
}

export const heroFragment = graphql`
  fragment DatoCmsBlocHeroFragment on DatoCmsBlocHero {
    model {
      apiKey
    }
    id
    texte
    items {
      id
      titre
    }
    image {
      url
      alt
    }
    titre
    vehicule {
      clefApi
    }
  }
`

export default Hero

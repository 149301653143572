import React from 'react'
import { graphql } from 'gatsby'

import Stars from '../components/Stars'
import TemoignageCard from '../components/TemoignageCard'

const Temoignage = ({ note, titre, temoignages }) => {
  return (
    <div className="relative py-12">
      <div className="absolute top-0 bottom-0 left-0 right-0 bg-gray-100 lg:bottom-1/4 z-index-1"></div>
      <div className="container relative px-4 mx-auto z-index-2">
        <div className="flex flex-col items-center p-4 my-auto">
          <h2 className="mb-6 text-3xl font-bold text-center">{titre}</h2>
          <div className="relative">
            <Stars className="text-gray-300" />
            <Stars
              className="absolute top-0 left-0 text-green-500"
              style={{ width: `${20 * note}%` }}
            />
          </div>
          <div className="mb-12 font-light text-gray-300">{note}/5</div>
          <div className="grid grid-cols-1 -mx-4 lg:grid-cols-3">
            {temoignages.map((props, index) => (
              <TemoignageCard {...props} key={index} />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export const temoignageFragment = graphql`
  fragment DatoCmsBlocTemoignageFragment on DatoCmsBlocTemoignage {
    model {
      apiKey
    }
    id
    note
    titre
    temoignages {
      id
      nom
      avatar {
        url
        alt
      }
      citation
    }
  }
`

export default Temoignage

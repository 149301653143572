import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

const Galerie = ({ images }) => {
  return (
    <div className="px-4">
      <div className="container relative grid grid-cols-8 gap-4 px-4 py-12 mx-auto">
        {images.map((image, index) => (
          <div
            className={`h-60 overflow-hidden rounded ${
              index === 1 ? 'col-span-2' : 'col-span-3'
            }`}
            key={index}
          >
            <GatsbyImage
              image={image.gatsbyImageData}
              alt={image.alt}
              className="w-full h-full"
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export const galerieFragment = graphql`
  fragment DatoCmsBlocGalerieFragment on DatoCmsBlocGalerie {
    model {
      apiKey
    }
    id
    images {
      alt
      gatsbyImageData(
        layout: CONSTRAINED
        imgixParams: { fit: "crop", crop: "focalpoint", w: "416", h: "250" }
      )
    }
  }
`

export default Galerie

import React, { useState } from 'react'
import getPath from '../../../getPath'
import Caret from '../../assets/images/caret.inline.svg'
import Link from '../../components/Link'

const FAQQuestion = ({ titre, chapo, couleur, slug, categorie }) => {
  const [collapsed, setCollapsed] = useState(true)
  return (
    <div className="relative p-8 my-12 rounded shadow-faq">
      <div
        onClick={() => setCollapsed(!collapsed)}
        className={`absolute flex items-center justify-center text-white bg-${couleur}-500 rounded-full w-7 h-7 top-8 right-8 transition-all transform cursor-pointer hover:bg-opacity-70 ${
          collapsed ? 'rotate-180' : 'rotate-0'
        }`}
      >
        <Caret className="fill-current" />
      </div>
      <Link to={getPath({ slug, categorie })}>
        <h3 className="text-xl font-bold cursor-pointer">{titre}</h3>
      </Link>
      {!collapsed && <div className="mt-3">{chapo}</div>}
    </div>
  )
}

export default FAQQuestion

import React from 'react'
import { graphql } from 'gatsby'

import ArticleCard from '../components/ArticleCard'

const Blog = ({ titre, articles }) => {
  return (
    <div className="py-12 pb-0 bg-gray-100 lg:pb-12">
      <div className="container mx-auto">
        <h2 className="mb-12 text-3xl font-bold text-center">{titre}</h2>
        <div className="grid grid-cols-1 lg:grid-cols-3">
          {articles.map((article) => (
            <ArticleCard {...article} key={article.id} />
          ))}
        </div>
      </div>
    </div>
  )
}

export const blogFragment = graphql`
  fragment DatoCmsBlocBlogFragment on DatoCmsBlocBlog {
    model {
      apiKey
    }
    id
    titre
    articles {
      ...DatoCmsArticleCardFragment
    }
  }
`

export default Blog

import { StaticQuery, graphql } from 'gatsby'
import React from 'react'

const VehiculesSelect = (props) => {
  return (
    <StaticQuery
      query={graphql`
        query AllVehiculesQuery {
          allDatoCmsChampsDeSouscriptionSelect(
            filter: { clefApi: { eq: "VEHICULE.CAT_VEH" } }
          ) {
            nodes {
              options {
                picto
                clefApi
                id
                label
              }
            }
          }
        }
      `}
      render={(data) => {
        const options =
          data.allDatoCmsChampsDeSouscriptionSelect.nodes[0].options
        return (
          <select
            className="w-full h-full px-4 bg-transparent appearance-none cursor-pointer"
            {...props}
          >
            <option disabled value="">
              Type de véhicule
            </option>
            {options.map(({ clefApi, label }) => (
              <option key={clefApi} value={clefApi}>
                {label}
              </option>
            ))}
          </select>
        )
      }}
    />
  )
}

export default VehiculesSelect

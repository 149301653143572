import { atom } from 'recoil'

const ecranState = atom({
  key: 'ecranState',
  default: 0,
  effects_UNSTABLE: [
    ({ onSet }) => {
      onSet((ecran) => {
        // console.debug(JSON.stringify(ecran))
        if (window.gtag) {
          window.gtag('event', 'go_to_step', {
            event_category: 'engagement',
            event_label: 'Go To Step',
            value: ecran,
          })
        }
      })
    },
  ],
})

export default ecranState

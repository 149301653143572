import React from 'react'
import { graphql, Link, StaticQuery } from 'gatsby'

const MoyenButtons = ({ prefix, ...props }) => (
  <StaticQuery
    query={graphql`
      query MoyenButtonsQuery {
        allDatoCmsMoyenAssure(sort: { order: ASC, fields: position }) {
          nodes {
            slug
            titre
            noteDeTitre
            couleur
          }
        }
      }
    `}
    render={(data) => (
      <div className="flex flex-wrap justify-center mb-12" {...props}>
        {data.allDatoCmsMoyenAssure.nodes.map((moyenAssure, index) => {
          return (
            <div key={index}>
              <Link
                key={moyenAssure.slug}
                to={`/${prefix}/${moyenAssure.slug}/`}
                className={`block p-2 rounded bg-white hover:bg-${
                  moyenAssure.couleur
                }-500 hover:bg-opacity-70 hover:text-white shadow-button ${
                  index > 0 && 'ml-4'
                }`}
                activeClassName={`bg-${moyenAssure.couleur}-500 shadow-none text-white`}
              >
                {moyenAssure.titre}
              </Link>
              {!!moyenAssure.noteDeTitre && (
                <div className="my-4 text-xs text-gray-300 lg:mb-0">
                  {moyenAssure.noteDeTitre}
                </div>
              )}
            </div>
          )
        })}
      </div>
    )}
  />
)

export default MoyenButtons

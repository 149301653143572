import React from 'react'
import { graphql } from 'gatsby'

const TitreSousTitre = ({ titre, sousTitre }) => {
  return (
    <div className={`container relative py-8 md:py-14 mx-auto text-center`}>
      <h1 className="mb-12 text-5xl font-bold">{titre}</h1>
      <h2 className="mb-6 text-xl">{sousTitre}</h2>
    </div>
  )
}

export const titreSousTitreFragment = graphql`
  fragment DatoCmsBlocTitreSousTitreFragment on DatoCmsBlocTitreSousTitre {
    model {
      apiKey
    }
    id
    titre
    sousTitre
  }
`

export default TitreSousTitre

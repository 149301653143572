const getPaiementDetails = (formule, ttc) => {
  let accompte, mensualite
  if (formule !== 'formule-unique') {
    accompte = ttc / 6
    mensualite = ttc / 12

    if (formule === 'F3') {
      accompte = (ttc - 5.9) / 6 + 5.9
      mensualite = (ttc - 5.9) / 12
    }
  } else {
    accompte = ttc / 4 + 20
    mensualite = ttc / 12
  }

  accompte = Math.ceil(accompte * 100) / 100
  mensualite = Math.ceil(mensualite * 100) / 100

  return { accompte, mensualite }
}

module.exports = getPaiementDetails

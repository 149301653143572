import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

const VehiculeCard = ({ titre, image, slug }) => {

  return (
    <Link to={`${slug}`} className="hover:opacity-90">
      <div className="relative flex-1 m-6 overflow-hidden bg-white rounded shadow-2xl">
        <GatsbyImage image={image.gatsbyImageData} alt={image.alt} />
        <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center text-center bg-gray-500 bg-opacity-50">
          <h3 className="text-2xl font-bold text-center text-white">{titre}</h3>
        </div>
      </div>
    </Link>
  )
}

export default VehiculeCard

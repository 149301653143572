import React from 'react'
import { graphql } from 'gatsby'

import Markdown from '../components/Markdown'

const Garantie = ({ titre, garanties }) => {
  return (
    <div className="container relative px-4 py-12 mx-auto">
      <div className="flex flex-col items-center p-4 my-auto">
        <h2 className="mb-24 text-3xl font-bold text-center">{titre}</h2>
        <div
          className={`grid grid-cols-1 ${
            garanties.length === 2 && 'lg:grid-cols-2 gap-12'
          } ${garanties.length === 3 && 'lg:grid-cols-3'}`}
        >
          {garanties.map(({ image, titre, texte }, index) => (
            <div
              className={`flex flex-col items-start ${
                garanties.length === 2 &&
                'shadow-2xl bg-white px-6 lg:px-12 py-8 rounded'
              } ${garanties.length === 3 && 'lg:px-6'}`}
              key={index}
            >
              <img className="mb-6" src={image.url} alt={image.alt} />
              <h3 className="mb-6 text-xl font-bold">{titre}</h3>
              <Markdown>{texte}</Markdown>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export const garantieFragment = graphql`
  fragment DatoCmsBlocGarantieFragment on DatoCmsBlocGarantie {
    model {
      apiKey
    }
    id
    titre
    garanties {
      id
      image {
        alt
        url
      }
      texte
      titre
    }
  }
`

export default Garantie

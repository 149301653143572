export const openExternalJolteeLink = (catId) => {
    const catMap = {
        1: 'trott',
        3: 'gyrop',
        4: 'gyror',
        5: 'speedb',
        6: 'skate',
        7: 'onewh',
        8: 'drais',
    };
    window.open('https://wizzas.joltee.fr?cat=' + catMap[catId], "_self", 'noopener,noreferrer');
};

export default openExternalJolteeLink

import React from 'react'
import { useRecoilState } from 'recoil'

import getPaiementDetails from '../utils/getPaiementDetails'

import Link from './Link'
import resultsState from './souscription/resultsState'

import Cross from '../assets/images/cross.inline.svg'
import Tick from '../assets/images/tick.inline.svg'
import typeState from './souscription/typeState'

const Formule = ({
  formule,
  moyenAssure,
  prix,
  onClick,
  disabledButton,
  actif,
  className,
}) => {
  const [resultats, setResultats] = useRecoilState(resultsState)
  const [_type, setType] = useRecoilState(typeState)

  // !! OLD
  // ⚠️ INFOPOL.GRATUITE undefined for VAE
  // const gratuite = !!prix
  //   ? prix.find((f) => f['POLICE.FORMULE'] === formule.clefApi)[
  //       'INFOPOL.GRATUITE'
  //     ]
  //   : null
  // const formuleUnique = formule.clefApi === 'formule-unique'
  // let prixTtc
  // check if VAE or EDPM and assign prixTtc accordingly
  // if (!!prix && formuleUnique) {
  //   prixTtc = prixWithoutRemise
  // } else {
  //   prixTtc = prixWithoutRemise - gratuite
  // }


  const prixWithoutRemise = !!prix
    ? prix.find((f) => f['POLICE.FORMULE'] === formule.clefApi)[
        'INFOPOL.TOTALSANS'
      ]
    : null

  // la balise POLICE.TTC_POL existe en VAE et EDPM et prend en compte la gratuité si elle existe.
  const prixTtc = !!prix
    ? prix.find((f) => f['POLICE.FORMULE'] === formule.clefApi)[
        'POLICE.TTC_POL'
      ]
    : null

  return (
    <div
      className={`lg:w-1/3 bg-white rounded items-center shadow-card relative overflow-hidden ${className}`}
    >
      {!!formule.label && (
        <div
          className={`absolute transform -rotate-45 -left-16 top-8 w-60 py-2 text-center bg-${
            moyenAssure.couleur === 'purple' ? 'green' : 'purple'
          }-500 text-white`}
        >
          {formule.label}
        </div>
      )}
      <div
        className={`mx-auto mb-6 flex items-center justify-center w-20 h-20 mb-4 rounded-full bg-opacity-15 ${
          !!actif
            ? `text-${moyenAssure.couleur}-500 bg-${moyenAssure.couleur}-500`
            : 'bg-gray-500'
        }`}
        dangerouslySetInnerHTML={{ __html: formule.picto }}
      />
      <h4 className="text-xl font-medium text-center">{formule.titre}</h4>
      <div
        className={`text-center mb-8 ${
          !!actif && `text-${moyenAssure.couleur}-500`
        }`}
      >
        {!prix && !!formule.avantPrix && (
          <span className="mr-2">{formule.avantPrix}</span>
        )}
        <span className="text-3xl font-bold">
          {!!prix && prix.length > 0
            ? `${parseFloat(
                resultats.cdFrac === 'A'
                  ? prixTtc
                  : getPaiementDetails(formule.clefApi, prixTtc).mensualite
              ).toFixed(2)}€/${resultats.cdFrac === 'A' ? 'an' : 'mois'}`
            : formule.prix}
        </span>
        {resultats.CODE_PROMO === 'OUI' ? (
          <p
            className={`text-xl text-center ${
              !!actif && `text-${moyenAssure.couleur}-500`
            } font-bold`}
          >
            Au lieu de{' '}
            {!!prix && prix.length > 0
              ? `${parseFloat(
                  resultats.cdFrac === 'A'
                    ? prixWithoutRemise
                    : getPaiementDetails(formule.clefApi, prixWithoutRemise)
                        .mensualite
                ).toFixed(2)}€/${resultats.cdFrac === 'A' ? 'an' : 'mois'}`
              : formule.prix}
            (*)
          </p>
        ) : (
          ''
        )}
      </div>
      {!prix && !!formule.apresPrix && (
        <div
          className={`mb-8 -mt-8 text-center ${
            !!actif && `text-${moyenAssure.couleur}-500`
          }`}
        >
          {formule.apresPrix}
        </div>
      )}
      <ul>
        {formule.items.map((item, index) => (
          <li
            key={item.id}
            className={`flex items-start ${
              formule.items.length > index + 1 ? 'mb-3' : ''
            }`}
            style={{ lineHeight: '17px' }}
          >
            {item.inclus ? (
              <Tick className="text-green-500 stroke-current" />
            ) : (
              <Cross className="text-gray-300 stroke-current" />
            )}
            <span
              className={`flex-1 ml-2 ${!item.inclus && 'text-gray-300'}`}
              dangerouslySetInnerHTML={{
                __html: item.item.titre.replace('\\n', '<br/>'),
              }}
            />
          </li>
        ))}
      </ul>
      {!disabledButton &&
        (!!onClick ? (
          <a
            className="block px-6 py-2 mx-auto mt-6 text-center border rounded cursor-pointer bg-purple-500  hover:bg-opacity-90"
            onClick={(e) => onClick(formule.clefApi)}
          >
            Je m'assure immédiatement
          </a>
        ) : (
          <Link
            onClick={() => {
              setResultats({
                ...resultats,
                formule: formule.clefApi,
                type: moyenAssure.slug,
              })
              setType({
                only: true,
              })
            }}
            className={`block mx-auto px-6 py-2 mt-6 text-center mx-auto rounded hover:bg-opacity-70 hover:border-opacity-70 ${
              !!actif ? `bg-${moyenAssure.couleur}-500 text-white` : 'border'
            }`}
            to={`/souscription`}
          >
            Je m'assure
          </Link>
        ))}
    </div>
  )
}

export default Formule

import { atom } from 'recoil'

const typeState = atom({
  key: 'typeState',
  default: {
    only: false,
  },
})

export default typeState

import React, { useState } from 'react'
import { graphql } from 'gatsby'

import VehiculeCard from '../components/VehiculeCard'
import Caret from '../assets/images/caret.inline.svg'
import Markdown from '../components/Markdown'

const Vehicule = ({ titre, vehicules }) => {
  const [expanded, expand] = useState(false)
  return (
    <div className="py-12">
      <div className="container px-4 mx-auto text-center">
        <h2 className="mb-12 text-3xl font-bold">{titre}</h2>
        <div className="grid grid-cols-1 -mx-6 lg:grid-cols-3">
          {vehicules.map(
            (vehicule, index) =>
              (expanded || index < 6) && (
                <VehiculeCard {...vehicule} key={vehicule.id} />
              )
          )}
          {vehicules.length === 1 && (
            <div className="col-span-2 p-6 text-left">
              <h3 className="mb-3 text-xl font-bold">{vehicules[0].titre}</h3>
              <Markdown>{vehicules[0].contenu}</Markdown>
            </div>
          )}
        </div>
        {vehicules.length > 6 && (
          <button
            className="inline-flex items-baseline text-purple-500"
            onClick={() => expand(!expanded)}
          >
            <span className="mr-2">
              {expanded ? 'Moins de véhicules' : 'Plus de véhicules'}
            </span>
            <Caret
              className={`fill-current transform ${
                expanded ? 'rotate-0' : 'rotate-180'
              }`}
            />
          </button>
        )}
      </div>
    </div>
  )
}

export const vehiculeFragment = graphql`
  fragment DatoCmsBlocVehiculeFragment on DatoCmsBlocVehicule {
    model {
      apiKey
    }
    id
    titre
    vehicules {
      id
      titre
      contenu
      slug
      image {
        alt
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`

export default Vehicule

import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'
import ClampLines from 'react-clamp-lines'

import Link from './Link'
import getPath from '../utils/getPath'

const ArticleCard = ({
  titre,
  chapo,
  image,
  expanded,
  slug,
  categorie,
  moyenAssure,
}) => {
  return (
    <div
      className={`relative flex-1 mx-4 mb-12 overflow-hidden bg-white rounded shadow-2xl ${
        expanded && 'lg:grid lg:grid-cols-2 lg:col-span-3 gap-6'
      }`}
    >
      <div className="relative order-2">
        {image && categorie.slug === 'articles' && (
          <GatsbyImage
            image={image.gatsbyImageData}
            alt={image.alt}
            className={`w-full ${expanded && 'absolute h-full'}`}
          />
        )}
      </div>
      <div
        className={`bg-white ${
          expanded ? 'order-1 p-12 lg:p-20' : 'order-3 p-6'
        }`}
      >
        {categorie.slug !== 'articles' && !!moyenAssure && (
          <Link
            className={`block mb-6 text-${moyenAssure.couleur}-500`}
            to={`/guides/${moyenAssure.slug}/`}
          >
            👉 {categorie.titre}
          </Link>
        )}
        <h3 className={`mb-6 ${expanded ? 'text-2xl' : 'text-xl'} font-bold`}>
          {titre}
        </h3>
        <ClampLines
          className="mb-6"
          innerElement="p"
          text={!!chapo ? chapo : ''}
          id="really-unique-id"
          lines={3}
          ellipsis="..."
          buttons={false}
        />
        <Link
          to={getPath({ titre, slug, categorie })}
          className={
            categorie.slug === 'article' || !moyenAssure
              ? 'text-green-500'
              : `text-${moyenAssure.couleur}-500`
          }
        >
          {categorie.slug === 'articles' ? "Lire l'article" : 'Lire le guide'}
        </Link>
      </div>
    </div>
  )
}

export const articleCardFragment = graphql`
  fragment DatoCmsArticleCardFragment on DatoCmsArticle {
    id
    titre
    chapo
    slug
    categorie {
      titre
      slug
    }
    moyenAssure {
      couleur
      slug
    }
    image {
      alt
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
`

export default ArticleCard

import React from 'react'

const TemnoignageCard = ({ nom, avatar, citation }) => {
  return (
    <div className="relative flex flex-col items-center flex-1 p-8 mx-4 mb-12 bg-white rounded shadow-2xl lg:mb-4">
      <img
        className="absolute top-0 transform -translate-x-1/2 -translate-y-1/2 left-1/2"
        src={avatar.url}
        alt={avatar.alt}
        width="76px"
        height="76px"
      />
      <svg
        className="my-6"
        xmlns="http://www.w3.org/2000/svg"
        width="39"
        height="32"
      >
        <path
          d="M13.993 3.34c-5.123 2.043-9.22 7.152-9.22 11.75 0 1.022 0 2.044.511 2.555 1.024-1.022 2.561-1.533 4.61-1.533 4.1 0 7.685 3.066 7.685 7.664s-3.586 7.664-7.685 7.664c-4.098.51-6.147-1.022-7.684-3.577C.673 25.309.161 21.733.161 19.689.161 11.514 4.26 4.872 12.968.785l1.025 2.555m21.003 0c-5.122 2.043-9.22 7.152-9.22 11.75 0 1.022 0 2.044.512 2.555 1.024-1.022 2.561-1.533 4.61-1.533 4.098 0 7.684 3.066 7.684 7.664s-3.586 7.664-7.684 7.664c-3.586 0-5.635-1.533-7.172-4.088-2.56-2.043-2.56-5.62-2.56-7.663 0-8.175 4.097-14.817 12.806-18.904l1.024 2.555"
          fill="#000042"
          fillRule="nonzero"
        />
      </svg>
      <p className="mb-6 text-xl text-center">{citation}</p>
      <div className="text-gray-400">{nom}</div>
    </div>
  )
}

export default TemnoignageCard

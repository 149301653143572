import React, { useState, useEffect, useRef } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Markdown from '../components/Markdown'
import ArticleCard from '../components/ArticleCard'
// import NewsletterCard from '../components/NewsletterCard'
import MoyenButtons from '../components/MoyenButtons'

const HeroBlog = ({ texte, titre, moyenAssure, categories }) => {
  const data = useStaticQuery(graphql`
    {
      allDatoCmsArticle {
        nodes {
          ...DatoCmsArticleCardFragment
        }
      }
    }
  `)

  // Array of all news articles
  const articles = data.allDatoCmsArticle.nodes.filter((article) => {
    return (
      !!article.categorie &&
      categories
        .map((categorie) => categorie.slug)
        .indexOf(article.categorie.slug) > -1 &&
      (!moyenAssure || moyenAssure.slug === article.moyenAssure?.slug)
    )
  })

  // State for the list
  const [list, setList] = useState([...articles.slice(0, 10)])

  // State to trigger oad more
  const [loadMore, setLoadMore] = useState(false)

  // State of whether there is more to load
  const [hasMore, setHasMore] = useState(articles.length > 10)

  //Set a ref for the loading div
  const loadRef = useRef()

  // Handle intersection with load more div
  const handleObserver = (entities) => {
    const target = entities[0]
    if (target.isIntersecting) {
      setLoadMore(true)
    }
  }

  //Initialize the intersection observer API
  useEffect(() => {
    var options = {
      root: null,
      rootMargin: '20px',
      threshold: 1.0,
    }
    const observer = new IntersectionObserver(handleObserver, options)
    if (loadRef.current) {
      observer.observe(loadRef.current)
    }
  }, [])

  // Handle loading more articles
  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list.length
      const isMore = currentLength < articles.length
      const nextResults = isMore
        ? articles.slice(currentLength, currentLength + 10)
        : []
      setList([...list, ...nextResults])
      setLoadMore(false)
    }
  }, [loadMore, hasMore]) //eslint-disable-line

  //Check if there is more
  useEffect(() => {
    const isMore = list.length < articles.length
    setHasMore(isMore)
  }, [list]) //eslint-disable-line

  return (
    <div className="container py-12 mx-auto">
      <h1 className="mb-12 text-5xl font-bold text-center">{titre}</h1>
      <Markdown className="px-4 mb-24 text-center">{texte}</Markdown>
      {moyenAssure && <MoyenButtons prefix="guides" />}
      <div className="grid grid-cols-1 lg:grid-cols-3">
        {list.map((article, index) => (
          <React.Fragment key={index}>
            <ArticleCard
              key={article.id}
              expanded={index === 0 && article.categorie.slug === 'articles'}
              {...article}
            />
            {/* Remove this block because form inactive */}
            {/* {index === 2 && article.categorie.slug === 'articles' && (
              <NewsletterCard />
            )} */}
          </React.Fragment>
        ))}
      </div>
      <div ref={loadRef}>
        {hasMore && <p className="text-center text-gray-300">Chargement...</p>}
      </div>
    </div>
  )
}

export const heroBlogFragment = graphql`
  fragment DatoCmsBlocHeroBlogFragment on DatoCmsBlocHeroBlog {
    model {
      apiKey
    }
    id
    titre
    texte
    categories {
      slug
    }
    moyenAssure {
      slug
      couleur
    }
  }
`

export default HeroBlog

import { atom } from 'recoil'

const vaeResults = {
  11000061: '1',
  11000063: '1',
  11000100: '1',
  ID_POL: '49a136a4c93',
  cdFrac: 'M',
  'VEHICULE.CAT_VEH': '1',
  'VEHICULE.VITESSE': '25',
  'INFOPOL.DATEACHAT': '2021-08-30T22:00:00.000Z',
  'VEHICULE.VALD_VEH': '900',
  'VEHICULE.MARQUE_VEH': 'Xiaomi',
  'VEHICULE.MODELE_VEH': 'm365',
  'VEHICULE.IMMAT_VEH': '23448848473',
  'PERSONNES.SOUSCRIPTEUR.CIVILITE': 'M',
  'PERSONNES.SOUSCRIPTEUR.PRENOM': 'Brad',
  'PERSONNES.SOUSCRIPTEUR.NOM': 'Pitt',
  'PERSONNES.SOUSCRIPTEUR.MAIL': 'brad.pitt@hello.com',
  'INFOPOL.DATENAISS': '1991-11-25T23:00:00.000Z',
  'VEHICULE.CPGARAGE_VEH': '95',
  'SOUSCRIPTEUR.NATIONALITE': 'FRA',
  CODE_PROMO: 'NON',
  formule: 'formule-unique',
  'PERSONNES.SOUSCRIPTEUR.ADRESSES.ADRESSE1': 'Rue de Charonne',
  'PERSONNES.SOUSCRIPTEUR.ADRESSES.CP': '75011',
  'PERSONNES.SOUSCRIPTEUR.ADRESSES.VOIE': '148',
  'PERSONNES.SOUSCRIPTEUR.ADRESSES.VILLE': 'Paris',
  'personnes.souscripteur.adresses.adresse1': {
    label: '148 Rue de Charonne, Paris, France',
    value: {
      description: '148 Rue de Charonne, Paris, France',
      matched_substrings: [
        { length: 3, offset: 0 },
        { length: 15, offset: 4 },
      ],
      place_id: 'ChIJkdb7VHVy5kcRiHBw1YRGBl4',
      reference: 'ChIJkdb7VHVy5kcRiHBw1YRGBl4',
      structured_formatting: {
        main_text: '148 Rue de Charonne',
        main_text_matched_substrings: [
          { length: 3, offset: 0 },
          { length: 15, offset: 4 },
        ],
        secondary_text: 'Paris, France',
      },
      terms: [
        { offset: 0, value: '148' },
        { offset: 4, value: 'Rue de Charonne' },
        { offset: 21, value: 'Paris' },
        { offset: 28, value: 'France' },
      ],
      types: ['premise', 'geocode'],
    },
  },
  'PERSONNES.SOUSCRIPTEUR.MOBILE': '+33658905621',
  'PERSONNES.SOUSCRIPTEUR.RIB.IBAN': 'FRXX XXXX XXXX XXXX XXX',
  'PERSONNES.SOUSCRIPTEUR.RIB.BIC': 'ABCDEFGH',
  // 'SOUSCRIPTEUR.POUR_UN_AUTRE': 'NON',
  type: 'vae',
}

const edpmResults = {
  11000061: '1',
  11000063: '1',
  11000100: '1',
  ID_POL: '11125130',
  cdFrac: 'M',
  type: 'edpm',
  formule: 'F2',
  'VEHICULE.CAT_VEH': '1',
  'VEHICULE.VITESSE': '25',
  'INFOPOL.DATEACHAT': '2021-08-31T22:00:00.000Z',
  'VEHICULE.VALD_VEH': '900',
  'VEHICULE.MARQUE_VEH': 'Xiaomi',
  'VEHICULE.MODELE_VEH': 'm365',
  'VEHICULE.IMMAT_VEH': '2340000493934',
  'PERSONNES.SOUSCRIPTEUR.CIVILITE': 'M',
  'PERSONNES.SOUSCRIPTEUR.PRENOM': 'Brad',
  'PERSONNES.SOUSCRIPTEUR.NOM': 'Pitt',
  'PERSONNES.SOUSCRIPTEUR.MAIL': 'brad.pitt@hello.com',
  'INFOPOL.DATENAISS': '1991-11-25T23:00:00.000Z',
  'VEHICULE.CPGARAGE_VEH': '95',
  CODE_PROMO: 'NON',
  'PERSONNES.SOUSCRIPTEUR.ADRESSES.ADRESSE1': "Rue de l'Université",
  'PERSONNES.SOUSCRIPTEUR.ADRESSES.CP': '75007',
  'PERSONNES.SOUSCRIPTEUR.ADRESSES.VOIE': '148',
  'PERSONNES.SOUSCRIPTEUR.ADRESSES.VILLE': 'Paris',
  'personnes.souscripteur.adresses.adresse1': {
    label: "148 Rue de l'Université, Paris, France",
    value: {
      description: "148 Rue de l'Université, Paris, France",
      matched_substrings: [
        { length: 3, offset: 0 },
        { length: 3, offset: 4 },
      ],
      place_id: 'ChIJFUsMTNlv5kcRmRjGzxkuIik',
      reference: 'ChIJFUsMTNlv5kcRmRjGzxkuIik',
      structured_formatting: {
        main_text: "148 Rue de l'Université",
        main_text_matched_substrings: [
          { length: 3, offset: 0 },
          { length: 3, offset: 4 },
        ],
        secondary_text: 'Paris, France',
      },
      terms: [
        { offset: 0, value: '148' },
        { offset: 4, value: "Rue de l'Université" },
        { offset: 25, value: 'Paris' },
        { offset: 32, value: 'France' },
      ],
      types: ['street_address', 'geocode'],
    },
  },
  'PERSONNES.SOUSCRIPTEUR.MOBILE': '+33658905621',
  'PERSONNES.SOUSCRIPTEUR.RIB.IBAN': 'FRXX XXXX XXXX XXX',
  'PERSONNES.SOUSCRIPTEUR.RIB.BIC': 'ABCDEFGH',
  // 'SOUSCRIPTEUR.POUR_UN_AUTRE': 'NON',
}

export const defaultResultsState = {
  cdFrac: 'M',
  type: null,
}

const dimensions = {
  'VEHICULE.CAT_VEH': 'categorie_vehicule',
  type: 'type',
  formule: 'formule',
  'INFOPOL.DATEACHAT': 'date_achat',
}

const resultsState = atom({
  key: 'resultsState',
  // default: vaeResults,
  default: defaultResultsState,
  effects_UNSTABLE: [
    ({ onSet }) => {
      onSet((resultats, oldResultats) => {
        // console.debug(JSON.stringify(resultats))
        if (window.gtag) {
          Object.keys(dimensions).forEach((key) => {
            if (!oldResultats[key] && !!resultats[key]) {
              window.gtag('event', `${dimensions[key]}_dimension`, {
                [dimensions[key]]: resultats[key],
              })
            }
          })
        }
      })
    },
  ],
})

export default resultsState

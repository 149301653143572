import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { useRecoilState } from 'recoil'
import resultsState from './souscription/resultsState'
import Formule from './Formule'

const Formules = ({ moyenAssureSlug, prix, onClick, actif, className }) => {
  return (
    <StaticQuery
      query={graphql`
        query moyenAssures {
          allDatoCmsMoyenAssure {
            nodes {
              slug
              couleur
              formules {
                clefApi
                slug
                apresPrix
                avantPrix
                id
                items {
                  id
                  inclus
                  item {
                    titre
                  }
                }
                label
                picto
                prix
                titre
                slug
              }
            }
          }
        }
      `}
      render={(data) => {
        const moyenAssure = data.allDatoCmsMoyenAssure.nodes.find(
          ({ slug }) => slug === moyenAssureSlug
        )
        const [resultats, setResultats] = useRecoilState(resultsState)

        return (
          <div
            className={`px-6 lg:px-0 lg:flex flex-wrap items-center justify-center ${
              className || ''
            }`}
          >
            {moyenAssure.formules
              .filter(
                (formule) =>
                  typeof actif === 'undefined' || formule.clefApi === actif
              )
              .map((formule) => {
                return (
                  <Formule
                    className={
                      !!formule.label
                        ? 'z-10 pt-24 px-12 pb-12'
                        : 'pt-12 pb-12 px-6 my-12'
                    }
                    formule={formule}
                    moyenAssure={moyenAssure}
                    prix={prix}
                    onClick={onClick}
                    key={formule.clefApi}
                    actif={!!formule.label}
                  />
                )
              })}
            {resultats.CODE_PROMO === 'OUI' ? <p className="text-md text-red-500 font-bold mt-6">
              (*) Votre prime à compter de la 2ème année. Votre remise de 1ère
              année ne s’applique pas à la taxe attentat de 5.90 € TTC
            </p>: '' }
          </div>
        )
      }}
    />
  )
}

export default Formules

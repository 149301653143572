import React from 'react'
import { graphql } from 'gatsby'

const Encart = ({ titre, contenu, design }) => {
  return (
    <div className="container relative px-4 mx-auto">
      <div
        className={`py-12 md:py-24 ${
          design === 'gauche-droite'
            ? 'grid grid-cols-1 md:grid-cols-2 gap-3'
            : 'px-6 bg-gray-100 text-center'
        }`}
      >
        <h2 className="mb-6 text-4xl font-bold">{titre}</h2>
        <div
          className={`max-w-lg mx-auto leading-relaxed ${
            design === 'encart gris' && 'font-bold'
          }`}
          dangerouslySetInnerHTML={{ __html: contenu }}
        ></div>
      </div>
    </div>
  )
}

export const encartFragment = graphql`
  fragment DatoCmsBlocEncartFragment on DatoCmsBlocEncart {
    model {
      apiKey
    }
    id
    titre
    contenu
    design
  }
`

export default Encart

import React from 'react'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import { graphql } from 'gatsby'

import Layout from '../partials/Layout'

import Blog from '../blocks/Blog'
import Galerie from '../blocks/Galerie'
import Garantie from '../blocks/Garantie'
import Encart from '../blocks/Encart'
import FAQ from '../blocks/FAQ'
import Hero from '../blocks/Hero'
import HeroBlog from '../blocks/HeroBlog'
import HeroFormule from '../blocks/HeroFormule'
import TextEtImage from '../blocks/ImageEtTexte'
import Temoignage from '../blocks/Temoignage'
import TitreSousTitre from '../blocks/TitreSousTitre'
import Vehicule from '../blocks/Vehicule'
import Texte from '../blocks/Texte'

const Normal = ({ data }) => (
  <Layout>
    <HelmetDatoCms seo={data.datoCmsPage.seoMetaTags} />
    {data.datoCmsPage.contenu.map((block, index) => (
      <React.Fragment key={index}>
        {block.model.apiKey === 'bloc_blog' && <Blog {...block} />}
        {block.model.apiKey === 'bloc_galerie' && <Galerie {...block} />}
        {block.model.apiKey === 'bloc_garantie' && <Garantie {...block} />}
        {block.model.apiKey === 'bloc_encart' && <Encart {...block} />}
        {block.model.apiKey === 'bloc_hero' && <Hero {...block} />}
        {block.model.apiKey === 'bloc_hero_blog' && <HeroBlog {...block} />}
        {block.model.apiKey === 'bloc_hero_formule' && (
          <HeroFormule {...block} prefix={data.datoCmsPage.typeDePage} />
        )}
        {block.model.apiKey === 'bloc_image_et_texte' && (
          <TextEtImage {...block} />
        )}
        {block.model.apiKey === 'bloc_temoignage' && <Temoignage {...block} />}
        {block.model.apiKey === 'bloc_texte' && <Texte {...block} />}
        {block.model.apiKey === 'bloc_titre_sous_titre' && (
          <TitreSousTitre {...block} />
        )}
        {block.model.apiKey === 'bloc_vehicule' && <Vehicule {...block} />}
        {block.model.apiKey === 'bloc_faq' && (
          <FAQ
            {...block}
            moyenAssure={
              data.datoCmsPage.contenu.find(
                (block) => block.model.apiKey === 'bloc_hero_formule'
              )?.moyenAssure
            }
          />
        )}
      </React.Fragment>
    ))}
  </Layout>
)

export default Normal

export const query = graphql`
  query NormalPageQuery($id: String) {
    datoCmsPage(id: { eq: $id }) {
      id
      slug
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      typeDePage
      contenu {
        ... on DatoCmsBlocBlog {
          ...DatoCmsBlocBlogFragment
        }
        ... on DatoCmsBlocEncart {
          ...DatoCmsBlocEncartFragment
        }
        ... on DatoCmsBlocFaq {
          ...DatoCmsBlocFaqFragment
        }
        ... on DatoCmsBlocGalerie {
          ...DatoCmsBlocGalerieFragment
        }
        ... on DatoCmsBlocGarantie {
          ...DatoCmsBlocGarantieFragment
        }
        ... on DatoCmsBlocHero {
          ...DatoCmsBlocHeroFragment
        }
        ... on DatoCmsBlocHeroBlog {
          ...DatoCmsBlocHeroBlogFragment
        }
        ... on DatoCmsBlocHeroFormule {
          ...DatoCmsBlocHeroFormuleFragment
        }
        ... on DatoCmsBlocImageEtTexte {
          ...DatoCmsBlocImageEtTexteFragment
        }
        ... on DatoCmsBlocTemoignage {
          ...DatoCmsBlocTemoignageFragment
        }
        ... on DatoCmsBlocTexte {
          ...DatoCmsBlocTexteFragment
        }
        ... on DatoCmsBlocTitreSousTitre {
          ...DatoCmsBlocTitreSousTitreFragment
        }
        ... on DatoCmsBlocVehicule {
          ...DatoCmsBlocVehiculeFragment
        }
      }
    }
  }
`
